import Dexie from 'dexie';

import { logger } from '@/logger';

import { InternalQueueItem, PartitionQueueRepository, StringKey } from './partition-queue.types';

export interface IndexedQueueItem<T, P extends string = string> extends InternalQueueItem<T, P> {
  sessionId: string;
}

interface LatestSequenceItem<P extends string = 'string'> {
  sessionId: string;
  partition: P;
  latestSequenceId: number;
}

class PartitionQueueDexieDatabase<T, P extends string = string> extends Dexie {
  readonly queue!: Dexie.Table<IndexedQueueItem<T, P>>;
  readonly sequence!: Dexie.Table<LatestSequenceItem<P>>;

  constructor() {
    super('PartitionQueueDatabase', {
      chromeTransactionDurability: 'strict',
    });

    // TODO - Review these indexes
    this.version(1).stores({
      queue: `[sessionId+priority+sequenceId], [sessionId+partition+sequenceId], [partition+sessionId] `,
      sequence: '[sessionId+partition]',
    });
  }
}

// TODO - I think we could improve the generic typing in this file.

export class IdbPartitionQueueRepository<T extends Record<string, unknown>> implements PartitionQueueRepository<T> {
  private readonly db = new PartitionQueueDexieDatabase();

  async getLatestSequenceId<P extends StringKey<T>>(sessionId: string, partition: P): Promise<number | null> {
    const item = await this.db.sequence.get({ sessionId, partition });
    return item?.latestSequenceId ?? null;
  }

  async addQueueItem<P extends StringKey<T>>(sessionId: string, item: InternalQueueItem<T[P], P>): Promise<void> {
    await this.db.transaction('rw', this.db.queue, this.db.sequence, async () => {
      const latestSequenceId = await this.getLatestSequenceId(sessionId, item.partition);
      if (latestSequenceId !== null && latestSequenceId >= item.sequenceId) {
        logger.error('INVALID_SEQUENCE_ID', () => ({ latestSequenceId, sequenceId: item.sequenceId }));
        throw new Error('Cannot push a new item with a lower sequenceId than a previous pushed item');
      }

      await this.db.queue.put({
        ...item,
        sessionId,
      });
      await this.db.sequence.put({
        sessionId,
        partition: item.partition,
        latestSequenceId: item.sequenceId,
      });
    });
  }

  async removeQueueItems<P extends StringKey<T>>(
    sessionId: string,
    partition: P,
    sequenceIds: number[],
  ): Promise<void> {
    await this.db.queue
      .where(['sessionId', 'partition', 'sequenceId'])
      .anyOf(sequenceIds.map((sequenceId) => [sessionId, partition, sequenceId]))
      .delete();
  }

  async getNextQueueItem<P extends StringKey<T>>(
    sessionId: string,
    offset?: number,
  ): Promise<InternalQueueItem<T[P], P> | null> {
    const item = await this.db.queue
      .where('sessionId')
      .equals(sessionId)
      .offset(offset ?? 0)
      .first();
    if (item) {
      return item as unknown as InternalQueueItem<T[P], P>;
    }
    return null;
  }

  async clear(sessionId: string): Promise<void> {
    await this.db.transaction('rw', this.db.queue, this.db.sequence, async () => {
      await this.db.queue.where('sessionId').equals(sessionId).delete();
      await this.db.sequence.where('sessionId').equals(sessionId).delete();
    });
  }

  async size<P extends StringKey<T>>(sessionId: string, partition?: P): Promise<number> {
    if (partition) {
      return this.db.queue.where(['partition', 'sessionId']).equals([partition, sessionId]).count();
    }

    return this.db.queue.where('sessionId').equals(sessionId).count();
  }
}
