/* eslint-disable react-refresh/only-export-components */
import React, { createContext, useContext } from 'react';
import { EntityType } from '@remento/types/entity';
import { RementoError } from '@remento/types/error';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Cookies from 'js-cookie';

import { logger } from '@/logger';
import { InterviewPartitionQueueRecord } from '@/modules/conversation-recorder/interview';
import { IdbPartitionQueueRepository } from '@/modules/conversation-recorder/partition-queue/partition-queue.idb.repository.ts';
import { DefaultRecordingSessionRepository } from '@/modules/conversation-recorder/recording-session/recording-session.repository';
import { DefaultIntercomService, IntercomService } from '@/services/analytics/intercom';
import { AssetCacheService, AssetService, DefaultAssetCacheService, DefaultAssetService } from '@/services/api/asset';
import { BookCacheService, BookService, DefaultBookCacheService, DefaultBookService } from '@/services/api/book';
import { EntityCacheManagerService, EntityCacheManagerServiceImpl } from '@/services/api/cache';
import {
  DefaultPersonCacheService,
  DefaultPersonService,
  PersonCacheService,
  PersonService,
} from '@/services/api/person';
import { DefaultRecordingCacheService } from '@/services/api/recording';
import { DefaultStoryCacheService, DefaultStoryService, StoryCacheService, StoryService } from '@/services/api/story';
import { DefaultUserCacheService } from '@/services/api/user/user.service.cache';

import { auth } from './firebase';
import { getQueryParam } from './hooks/useQueryParam';
import { LocalInterviewSessionRepository } from './modules/conversation-recorder/interview-session/interview-session.repository';
import { InterviewSessionRepository } from './modules/conversation-recorder/interview-session/interview-session.types';
import { DefaultRecordingSessionService } from './modules/conversation-recorder/recording-session/recording-session.service';
import {
  RecordingSessionRepository,
  RecordingSessionService,
} from './modules/conversation-recorder/recording-session/recording-session.types';
import { onboardingDialogs } from './modules/onboarding-dialog/onboarding-dialogs';
import { ONBOARDING_DIALOG_BLACKLIST } from './modules/routing';
import { getRouteFromPathname } from './modules/routing/routes';
import { RoutePath } from './modules/routing/types/routing.types';
import { getPathname } from './modules/routing/utils/location';
import { ConsoleAnalyticsRepository } from './services/analytics/analytics.repository.console';
import { SegmentAnalyticsRepository } from './services/analytics/analytics.repository.segment';
import { AnalyticsServiceImpl } from './services/analytics/analytics.service';
import { AnalyticsRepository, AnalyticsService } from './services/analytics/analytics.types';
import { AnalyticsPropertyRepositoryImpl } from './services/analytics/analytics-property/analytics-property.repository';
import { CheckoutAnalyticsService, DefaultCheckoutAnalyticsService } from './services/analytics/checkout-analytics';
import {
  CollaborationAnalyticsService,
  DefaultCollaborationAnalyticsService,
} from './services/analytics/collaboration-analytics';
import { DatadogTracingServiceImpl } from './services/analytics/datadog/datadog-tracing.service';
import { NoopDatadogTracingService } from './services/analytics/datadog/datadog-tracing.service.noop';
import { DatadogTracingService } from './services/analytics/datadog/datadog-tracing.types';
import {
  DefaultOnboardingAnalyticsService,
  OnboardingAnalyticsService,
} from './services/analytics/onboarding-analytics';
import { DefaultPollAnalyticsService, PollAnalyticsService } from './services/analytics/poll-analytics';
import { DefaultProjectAnalyticsService, ProjectAnalyticsService } from './services/analytics/project-analytics';
import { DefaultPromptAnalyticsService } from './services/analytics/prompt-analytics/prompt-analytics.service';
import { PromptAnalyticsService } from './services/analytics/prompt-analytics/prompt-analytics.types';
import {
  DefaultQuestionnaireAnalyticsService,
  QuestionnaireAnalyticsService,
} from './services/analytics/questionnaire-analytics';
import { DefaultReferralAnalyticsService, ReferralAnalyticsService } from './services/analytics/referral-analytics';
import { DefaultStoryViewerAnalytics } from './services/analytics/story-viewer-analytics/story-viewer-analytics.service';
import { StoryViewerAnalytics } from './services/analytics/story-viewer-analytics/story-viewer-analytics.types';
import { DefaultStorytellingAnalyticsService } from './services/analytics/storytelling-analytics/storytelling-analytics.service';
import { StorytellingAnalyticsService } from './services/analytics/storytelling-analytics/storytelling-analytics.types';
import { DefaultSurveyAnalyticsService, SurveyAnalyticsService } from './services/analytics/survey-analytics';
import { UserAnalyticsServiceImpl } from './services/analytics/user-analytics/user-analytics.service';
import { WebappAnalyticsServiceImpl } from './services/analytics/webapp-analytics/webapp-analytics.service';
import { WebappAnalyticsService } from './services/analytics/webapp-analytics/webapp-analytics.types';
import { AclCacheService, AclService, DefaultAclCacheService, DefaultAclService } from './services/api/acl';
import { AUTH_PENDING_TRANSITION_KEY, AUTH_STATE_KEY, AuthRepositoryImpl } from './services/api/auth/auth.repository';
import { AuthServiceImpl } from './services/api/auth/auth.service';
import { AuthService, AuthStateType } from './services/api/auth/auth.types';
import { AuthorizationService, DefaultAuthorizationService } from './services/api/authorization';
import { BrowserDataServiceImpl } from './services/api/browser-data/browser-data.service';
import { BrowserDataService } from './services/api/browser-data/browser-data.types';
import { CheckoutService, DefaultCheckoutService } from './services/api/checkout';
import {
  DefaultPollCacheService,
  DefaultPollService,
  LocalPollAnonymousVoteRepository,
  PollCacheService,
  PollService,
} from './services/api/poll';
import { DefaultProjectBannerService, PROJECT_BANNER_STORE_PREFIX, ProjectBannerService } from './services/api/project';
import { DefaultProjectService } from './services/api/project/project.service';
import { DefaultProjectCacheService } from './services/api/project/project.service.cache';
import { ProjectCacheService } from './services/api/project/project.types';
import { ProjectService } from './services/api/project/project.types';
import { OptimisticPromptMutationManager } from './services/api/project/prompt-mutation.manager';
import {
  DefaultReactionCacheService,
  DefaultReactionService,
  ReactionCacheService,
  ReactionService,
} from './services/api/reaction';
import { DefaultRecordingService } from './services/api/recording/recording.service';
import { RecordingCacheService, RecordingService } from './services/api/recording/recording.types';
import { DefaultReferralService, ReferralService } from './services/api/referral';
import { HttpRementoIdService } from './services/api/remento-id/remento-id.service';
import { UserServiceImpl } from './services/api/user/user.service';
import { UserCacheService, UserService } from './services/api/user/user.types';
import { ConversionQuestionnaireServiceImpl } from './services/cms/conversion-questionnaire/conversion-questionnaire.service';
import { ConversionQuestionnaireService } from './services/cms/conversion-questionnaire/conversion-questionnaire.types';
import { MarketingSourceServiceImpl } from './services/cms/marketing-source/marketing-source.service';
import { MarketingSourceService } from './services/cms/marketing-source/marketing-source.types';
import { OnboardingServiceImpl } from './services/cms/onboarding/onboarding.service';
import { OnboardingService } from './services/cms/onboarding/onboarding.types';
import { PromptTemplateServiceImpl } from './services/cms/prompt-template/prompt-template.service';
import { PromptTemplateService } from './services/cms/prompt-template/prompt-template.types';
import { PromptTemplateTagsServiceImpl } from './services/cms/prompt-template-tags/prompt-template-tags.service';
import { PromptTemplateTagsService } from './services/cms/prompt-template-tags/prompt-template-tags.types';
import { createSanityClient } from './services/cms/sanity';
import { GeolocationServiceImpl } from './services/external/geolocation/geolocation.service';
import { GeolocationService } from './services/external/geolocation/geolocation.types';
import { SurveyService, WisepopsSurveyService } from './services/external/survey';
import { CueRepository, LocalCueRepository } from './services/local/cue';
import {
  LocalMemoryStoreRepository,
  LocalPersistentStoreRepository,
  LocalSessionStoreRepository,
  LocalStoreRepository,
} from './services/local/local-store';
import { LocalStoreRepositoryWrapper } from './services/local/local-store/local-store.repository.wrapper';
import {
  DefaultOnboardingDialogService,
  LocalOnboardingDialogRepository,
  OnboardingDialogRepository,
  OnboardingDialogService,
} from './services/local/onboarding-dialog';
import { RedirectServiceImpl } from './services/local/redirect/redirect.service';
import { RedirectService } from './services/local/redirect/redirect.types';
import { captureException } from './utils/captureException';
import { IS_DEV } from './utils/isDev';

// dayjs duration config
dayjs.extend(duration);

declare global {
  interface Window {
    SEGMENT_INSTANCE: unknown;
  }
}

export interface Services {
  authorizationService: AuthorizationService;
  analyticsService: AnalyticsService;
  authService: AuthService;
  userService: UserService;
  userCacheService: UserCacheService;
  browserDataService: BrowserDataService;
  datadogTracingService: DatadogTracingService;
  webappAnalyticsService: WebappAnalyticsService;
  promptAnalyticsService: PromptAnalyticsService;
  storyViewerAnalyticsService: StoryViewerAnalytics;
  storytellingAnalyticsService: StorytellingAnalyticsService;
  checkoutAnalyticsService: CheckoutAnalyticsService;
  onboardingAnalyticsService: OnboardingAnalyticsService;
  collaborationAnalyticsService: CollaborationAnalyticsService;
  pollAnalyticsService: PollAnalyticsService;
  questionnaireAnalyticsService: QuestionnaireAnalyticsService;
  projectAnalyticsService: ProjectAnalyticsService;
  surveyAnalyticsService: SurveyAnalyticsService;
  referralAnalyticsService: ReferralAnalyticsService;
  geolocationService: GeolocationService;
  sessionStorageRepository: LocalStoreRepository;
  localStorageRepository: LocalStoreRepository;
  recordingService: RecordingService;
  recordingCacheService: RecordingCacheService;
  cueRepository: CueRepository;
  surveyService: SurveyService;
  redirectService: RedirectService;
  recordingSessionService: RecordingSessionService;
  interviewSessionRepository: InterviewSessionRepository;
  entityCacheManagerService: EntityCacheManagerService;
  projectService: ProjectService;
  projectCacheService: ProjectCacheService;
  projectBannerService: ProjectBannerService;
  personService: PersonService;
  personCacheService: PersonCacheService;
  assetService: AssetService;
  assetCacheService: AssetCacheService;
  bookService: BookService;
  bookCacheService: BookCacheService;
  storyService: StoryService;
  storyCacheService: StoryCacheService;
  checkoutService: CheckoutService;
  intercomService?: IntercomService;
  aclService: AclService;
  aclCacheService: AclCacheService;
  reactionService: ReactionService;
  reactionCacheService: ReactionCacheService;
  pollService: PollService;
  pollCacheService: PollCacheService;
  recordingSessionRepository: RecordingSessionRepository;
  onboardingDialogRepository: OnboardingDialogRepository;
  onboardingDialogService: OnboardingDialogService;
  referralService: ReferralService;

  // CMS
  promptTemplateService: PromptTemplateService;
  promptTemplateTagService: PromptTemplateTagsService;
  marketingSourceService: MarketingSourceService;
  onboardingService: OnboardingService;
  conversionQuestionnaireService: ConversionQuestionnaireService;

  // React Query
  queryClient: QueryClient;
}

let sessionStorageRepository: LocalStoreRepository;
if (typeof window === 'undefined') {
  sessionStorageRepository = new LocalMemoryStoreRepository();
} else {
  sessionStorageRepository = new LocalSessionStoreRepository();
}

// We want to instantiate this as soon as possible
let datadogTracingService: DatadogTracingService & AnalyticsRepository;
if (typeof window !== 'undefined' && import.meta.env.PROD) {
  datadogTracingService = new DatadogTracingServiceImpl(
    new LocalStoreRepositoryWrapper('@remento/tracing', sessionStorageRepository),
    {
      throttledErrors: [
        // Likely user errors
        'unauthorized',
        'forbidden',
        'invalid-acl-token',
        // Random Safari errors, impacts users recording
        'Connection to Indexed Database server lost',
        'Failed to store record in an IDBObjectStore',
        // Happens after releases, code splitting problems
        'Importing a module script failed',
      ],
      ignoredErrors: [
        // Autoplay errors
        'play()',
        'The play method is not allowed by the user agent or the platform',
        // Random errors, always happen in the recording, but never prevents the user from recording or causes problems
        'Permission was denied',
        'The operation was aborted',
        // CORS errors, likely network related, doesn't affect sessions
        'Load failed',
        'Network error',
        'Failed to fetch',
        'NetworkError when attempting to fetch resource',
        'Script error',
        // User error
        'story-not-found',
        // WisePops
        'WisePops',
        // Network errors
        'Error (auth/network-request-failed)',
        'CanceledError: canceled',
        'AxiosError: timeout exceeded',
        'The fetching process for the media resource was aborted',
        // User enters the record page for a prompt that was deleted
        'prompt-not-found',
        // Happens when the user is not focused when we try to acquire the wake lock
        'Wake Lock permission request denied',
        "Failed to execute 'request' on 'WakeLock'",
        'Document is hidden',
      ],
    },
  );
} else {
  datadogTracingService = new NoopDatadogTracingService();
}

const REDIRECT_LOCAL_STORE_PREFIX = '@remento/redirects';
export const USER_COOKIE_NAME = 'book-webapp-user' + (IS_DEV ? '-dev' : '');
export const USER_COOKIE_DOMAIN = window.location.hostname.includes('remento.co')
  ? 'remento.co'
  : window.location.hostname;
export const MIGRATION_TOKEN_COOKIE_NAME = 'ro_book_webapp_migration_token';

export async function createServices(): Promise<Services> {
  logger.debug('SERVICES.CREATE', {});

  // Redirect from web.remento.co to remento.co.
  // The base path will already be set at this point.
  const activeRoute = getRouteFromPathname(getPathname());
  const isDevDomain = window.location.hostname.includes('dev.web.remento');
  const isStagingDomain = window.location.hostname.includes('staging.web.remento');

  if (
    (isDevDomain || isStagingDomain) &&
    window.location.hostname.includes('web.') &&
    activeRoute !== RoutePath.TokenRedirect
  ) {
    if (isDevDomain) {
      window.location.replace(window.location.href.replace('dev.web.remento', 'dev.remento.co'));
    } else if (isStagingDomain) {
      window.location.replace(window.location.href.replace('staging.web.remento', 'staging.remento.co'));
    } else {
      window.location.replace(window.location.href.replace('web.remento', 'remento.co'));
    }
  }

  let localStorageRepository: LocalStoreRepository;
  if (typeof window === 'undefined') {
    localStorageRepository = new LocalMemoryStoreRepository();
  } else {
    localStorageRepository = new LocalPersistentStoreRepository(
      new Set([AUTH_STATE_KEY, AUTH_PENDING_TRANSITION_KEY]),
      [REDIRECT_LOCAL_STORE_PREFIX],
    );
  }

  const rementoIdServerUrl = import.meta.env.VITE_ID_SERVER_URL ?? '';
  const rementoIdService = new HttpRementoIdService(rementoIdServerUrl);

  const analyticsPropertyRepository = new AnalyticsPropertyRepositoryImpl(localStorageRepository);
  let intercomService: IntercomService | undefined;
  const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID;
  if (intercomAppId) {
    intercomService = new DefaultIntercomService(localStorageRepository, intercomAppId);
  }

  let analyticsSource: string = import.meta.env.VITE_ANALYTICS_SOURCE ?? '';
  if (!analyticsSource.length) {
    analyticsSource = 'remento-book-webapp-dev';
    console.warn(`Missing VITE_ANALYTICS_SOURCE env variable, defaulting to ${analyticsSource}`);
  }

  const analyticsLocalStore = new LocalStoreRepositoryWrapper('@remento/analytics', sessionStorageRepository);
  let analyticsService: AnalyticsService;

  if (import.meta.env.PROD || import.meta.env.VITE_DEBUG_ANALYTICS === 'true') {
    const analyticsRepositories: AnalyticsRepository[] = [datadogTracingService];

    const segmentWriteKey = import.meta.env.VITE_SEGMENT_WRITE_KEY;
    if (segmentWriteKey) {
      const segmentAnalyticsRepository = new SegmentAnalyticsRepository(localStorageRepository, segmentWriteKey);
      analyticsRepositories.push(segmentAnalyticsRepository);
      window.SEGMENT_INSTANCE = segmentAnalyticsRepository.segment;
    } else {
      console.warn('Missing VITE_SEGMENT_WRITE_KEY env variable');
    }

    if (intercomService != null) {
      analyticsRepositories.push(intercomService);
    }

    if (getQueryParam('debug') == 'true') {
      analyticsRepositories.push(new ConsoleAnalyticsRepository());
    }

    analyticsService = new AnalyticsServiceImpl(
      analyticsRepositories,
      analyticsPropertyRepository,
      rementoIdService,
      analyticsLocalStore,
      analyticsSource,
    );
  } else {
    analyticsService = new AnalyticsServiceImpl(
      [new ConsoleAnalyticsRepository()],
      analyticsPropertyRepository,
      rementoIdService,
      analyticsLocalStore,
      analyticsSource,
    );
  }

  const sevenDays = 1000 * 60 * 60 * 24 * 7;
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        networkMode: 'always',
        throwOnError: true,
        retry: (failureCount, error) => {
          if (error instanceof RementoError && error.retriable) {
            return failureCount < 3;
          }
          return false;
        },
        // We will invalidate the cache ourselves (most times)
        gcTime: sevenDays,
        staleTime: sevenDays,
      },
    },
  });

  const browserDataService = new BrowserDataServiceImpl(localStorageRepository, {
    persistent: localStorageRepository,
    session: sessionStorageRepository,
  });

  const userAnalyticsRepository = new UserAnalyticsServiceImpl(analyticsService);
  const authRepository = new AuthRepositoryImpl(localStorageRepository);
  const authService: AuthService = new AuthServiceImpl(
    auth,
    analyticsService,
    userAnalyticsRepository,
    browserDataService,
    sessionStorageRepository,
    localStorageRepository,
    authRepository,
    queryClient,
    import.meta.env.VITE_PATH_BASENAME ?? '',
  );
  await authService.initialize();
  logger.debug('AUTH.INITIALIZED', {});

  // Redirect the user to the token redirect page if the user is:
  //   1: Signed out
  //   2: Accessing the new domain
  //   3: Have the USER_COOKIE_NAME cookie
  const isLocalhost = window.location.hostname === 'localhost' || /^[0-9]/.test(window.location.hostname);
  const isNewDomain = !isLocalhost && !window.location.hostname.includes('web.');
  const signedOut = authService.getAuthState() === AuthStateType.SignedOut;
  if (isNewDomain && signedOut && Cookies.get(USER_COOKIE_NAME) != null) {
    const queryParams = new URLSearchParams();
    queryParams.set('redirect', window.location.href);
    if (isDevDomain) {
      window.location.replace(`https://dev.web.remento.co${RoutePath.TokenRedirect}?${queryParams.toString()}`);
    } else if (isStagingDomain) {
      window.location.replace(`https://staging.web.remento.co${RoutePath.TokenRedirect}?${queryParams.toString()}`);
    } else {
      window.location.replace(`https://web.remento.co${RoutePath.TokenRedirect}?${queryParams.toString()}`);
    }
  }

  // Consume the migration token if available
  const migrationToken = Cookies.get(MIGRATION_TOKEN_COOKIE_NAME) ?? null;
  if (isNewDomain && signedOut && migrationToken !== null && migrationToken !== 'not-authenticated') {
    try {
      await authService.signInWithCustomToken(migrationToken);
    } catch (error) {
      captureException(error, true);
    }

    Cookies.remove(MIGRATION_TOKEN_COOKIE_NAME, {
      domain: USER_COOKIE_DOMAIN,
    });
  }

  const authorizationService = new DefaultAuthorizationService(
    authService,
    new LocalStoreRepositoryWrapper('@remento/authorization', sessionStorageRepository),
  );

  const entityCacheManagerService = new EntityCacheManagerServiceImpl(queryClient, authService, authorizationService);
  await entityCacheManagerService.initialize({
    [EntityType.PROMPT]: new OptimisticPromptMutationManager(entityCacheManagerService),
  });

  const personService = new DefaultPersonService(authorizationService);
  const personCacheService = new DefaultPersonCacheService(personService, entityCacheManagerService, queryClient);

  const userService = new UserServiceImpl(
    authService,
    authorizationService,
    entityCacheManagerService,
    personCacheService,
    analyticsService,
  );
  userService.onUserChanged(async (user) => {
    if (user == null) {
      Cookies.remove(USER_COOKIE_NAME, {
        domain: USER_COOKIE_DOMAIN,
      });
      return;
    }

    // Set the user cookie.
    // This is used in the remento.co website to identify
    // if the user is already an user in the remento book app
    try {
      const person = await personCacheService.getPerson(user.personId);
      Cookies.set(
        USER_COOKIE_NAME,
        JSON.stringify({
          ruid: user.ruid,
          email: user.communicationChannels.email,
          name: person?.name?.full ?? null,
        }),
        {
          // The Firebase refresh token is valid forever (or until the user is deleted),
          // but a cookie must have an expiration time.
          // Most browsers don't have a limit on the expiration date; only Chrome
          // has an upper limit of 400 days.
          expires: 365, // Value in days
          domain: USER_COOKIE_DOMAIN,
        },
      );
    } catch (error) {
      captureException(error, true);
    }
  });
  await userService.initialize();

  const webappAnalyticsService = new WebappAnalyticsServiceImpl(analyticsService);
  datadogTracingService?.onError((errorUrl) => {
    webappAnalyticsService.onError(errorUrl);
  });

  const promptAnalyticsService = new DefaultPromptAnalyticsService(analyticsService);
  const storyViewerAnalyticsService = new DefaultStoryViewerAnalytics(analyticsService);
  const storytellingAnalyticsService = new DefaultStorytellingAnalyticsService(analyticsService);
  const checkoutAnalyticsService = new DefaultCheckoutAnalyticsService(analyticsService);
  const onboardingAnalyticsService = new DefaultOnboardingAnalyticsService(analyticsService);
  const collaborationAnalyticsService = new DefaultCollaborationAnalyticsService(analyticsService);
  const pollAnalyticsService = new DefaultPollAnalyticsService(analyticsService);
  const questionnaireAnalyticsService = new DefaultQuestionnaireAnalyticsService(analyticsService);
  const projectAnalyticsService = new DefaultProjectAnalyticsService(analyticsService);
  const surveyAnalyticsService = new DefaultSurveyAnalyticsService(analyticsService);
  const referralAnalyticsService = new DefaultReferralAnalyticsService(analyticsService);

  const geolocationService = new GeolocationServiceImpl(import.meta.env.VITE_ARC_GIS_API_KEY ?? '');

  const cueRepository = new LocalCueRepository(localStorageRepository);
  const surveyService = new WisepopsSurveyService();

  const redirectService = new RedirectServiceImpl(
    new LocalStoreRepositoryWrapper(REDIRECT_LOCAL_STORE_PREFIX, localStorageRepository),
    import.meta.env.VITE_PATH_BASENAME ?? '',
  );

  const assetService = new DefaultAssetService(authService, authorizationService);
  const assetCacheService = new DefaultAssetCacheService(assetService, entityCacheManagerService, queryClient);

  const recordingSessionService = new DefaultRecordingSessionService(assetService);
  const interviewSessionRepository = new LocalInterviewSessionRepository(localStorageRepository);

  // Clearing old interview sessions
  const oldSessionIds = interviewSessionRepository.getOldInterviewSessions();
  const dbPartitionQueue = new IdbPartitionQueueRepository<InterviewPartitionQueueRecord>();
  // We don't want this to be awaited
  oldSessionIds.forEach(async (sessionId) => {
    try {
      await dbPartitionQueue.clear(sessionId);
      interviewSessionRepository.deleteInterviewSession(sessionId);
    } catch (e) {
      captureException(e);
    }
  });

  const sanityClient = createSanityClient();
  const promptTemplateService = new PromptTemplateServiceImpl(sanityClient);
  const promptTemplateTagService = new PromptTemplateTagsServiceImpl(sanityClient);
  const marketingSourceService = new MarketingSourceServiceImpl(sanityClient);
  const onboardingService = new OnboardingServiceImpl(sanityClient);
  const conversionQuestionnaireService = new ConversionQuestionnaireServiceImpl(sanityClient);

  const storyService = new DefaultStoryService(authorizationService);
  const storyCacheService = new DefaultStoryCacheService(
    storyService,
    entityCacheManagerService,
    queryClient,
    assetCacheService,
  );

  const reactionService = new DefaultReactionService(authorizationService);
  const reactionCacheService = new DefaultReactionCacheService(reactionService, entityCacheManagerService, queryClient);

  const projectService = new DefaultProjectService(authorizationService);
  const projectCacheService = new DefaultProjectCacheService(projectService, entityCacheManagerService, queryClient);
  const projectBannerService = new DefaultProjectBannerService(
    personCacheService,
    userService,
    new LocalStoreRepositoryWrapper(PROJECT_BANNER_STORE_PREFIX, localStorageRepository),
    new LocalStoreRepositoryWrapper(PROJECT_BANNER_STORE_PREFIX, sessionStorageRepository),
  );

  const bookService = new DefaultBookService(authorizationService);
  const bookCacheService = new DefaultBookCacheService(bookService, entityCacheManagerService, queryClient);

  const recordingService = new DefaultRecordingService(authorizationService);
  const recordingCacheService = new DefaultRecordingCacheService(
    recordingService,
    entityCacheManagerService,
    queryClient,
  );

  const userCacheService = new DefaultUserCacheService(userService, entityCacheManagerService, queryClient);

  const checkoutService = new DefaultCheckoutService(authorizationService);

  const aclService = new DefaultAclService(authorizationService);
  const aclCacheService = new DefaultAclCacheService(aclService, entityCacheManagerService, queryClient);

  const pollAnonymousVoteRepository = new LocalPollAnonymousVoteRepository(
    new LocalStoreRepositoryWrapper('@remento/poll-anonymous-votes', localStorageRepository),
  );
  const pollService = new DefaultPollService(pollAnonymousVoteRepository, authorizationService, userService);
  const pollCacheService = new DefaultPollCacheService(
    pollService,
    entityCacheManagerService,
    queryClient,
    pollAnonymousVoteRepository,
    userService,
    personCacheService,
  );

  const recordingSessionRepository = new DefaultRecordingSessionRepository(
    new LocalStoreRepositoryWrapper('@remento/recording-session-repository', sessionStorageRepository),
  );

  userService.onUserChanged((user) => {
    if (user != null) {
      pollCacheService.associateAnonymousPollVotes().catch((error) => captureException(error, true));
    }
  });

  const onboardingDialogRepository = new LocalOnboardingDialogRepository(
    new LocalStoreRepositoryWrapper('@remento/onboarding-dialog', sessionStorageRepository),
  );
  const onboardingDialogService = new DefaultOnboardingDialogService(
    onboardingDialogRepository,
    userService,
    projectCacheService,
    aclCacheService,
    authorizationService,
    onboardingDialogs,
    ONBOARDING_DIALOG_BLACKLIST,
  );

  const referralService = new DefaultReferralService(authorizationService);

  return {
    geolocationService,
    authorizationService,
    analyticsService,
    authService,
    userService,
    userCacheService,
    browserDataService,
    datadogTracingService,
    webappAnalyticsService,
    promptAnalyticsService,
    storyViewerAnalyticsService,
    storytellingAnalyticsService,
    checkoutAnalyticsService,
    onboardingAnalyticsService,
    collaborationAnalyticsService,
    pollAnalyticsService,
    questionnaireAnalyticsService,
    projectAnalyticsService,
    surveyAnalyticsService,
    referralAnalyticsService,
    sessionStorageRepository,
    localStorageRepository,
    cueRepository,
    surveyService,
    redirectService,
    recordingSessionService,
    interviewSessionRepository,
    promptTemplateService,
    promptTemplateTagService,
    marketingSourceService,
    onboardingService,
    conversionQuestionnaireService,
    queryClient,
    entityCacheManagerService,
    projectService,
    projectCacheService,
    projectBannerService,
    personService,
    personCacheService,
    assetService,
    assetCacheService,
    bookService,
    bookCacheService,
    storyService,
    storyCacheService,
    recordingService,
    recordingCacheService,
    recordingSessionRepository,
    checkoutService,
    intercomService,
    aclService,
    aclCacheService,
    reactionService,
    reactionCacheService,
    pollService,
    pollCacheService,
    onboardingDialogRepository,
    onboardingDialogService,
    referralService,
  };
}

export interface ServicesProviderProps {
  services: Services;
  children: React.ReactNode;
}

const ServicesContext = createContext<Services | undefined>(undefined);

export function ServicesProvider({ services, children }: ServicesProviderProps) {
  return (
    <ServicesContext.Provider value={services}>
      <QueryClientProvider client={services.queryClient}>{children}</QueryClientProvider>
    </ServicesContext.Provider>
  );
}

export function useServices(): Services {
  const context = useContext(ServicesContext);
  if (!context) {
    throw new Error('ServicesProvider must be used inside ServicesContext');
  }

  return context;
}
